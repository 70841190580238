import landing_data from './data/landing.json';
import { useSubscriptionService } from 'hooks/sales/subscription';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { SMSListPage } from './list';
import { useUserService } from 'hooks/iam/user';
import { useSMSService } from 'hooks/messaging/sms';
import { Spacer } from 'app/layouts/generic';
import { LandingCard } from 'app/shared/onboarding/landing';

export const SMSModule = () => {
  const dispatch = useDispatch();
  const { fetchSMSs } = useSMSService();
  const { fetchSubscription } = useSubscriptionService();
  const { fetchUserData } = useUserService();

  const [has_subscription, setHasSubscription] = useState(false);
  const [onboarding_config, setOnboardingConfig] = useState({
    hidden: true,
    has_created_message: false
  });

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Messaging', path: '/messaging/sms' },
        { title: 'SMS', path: '.' }
      ])
    );

    fetchSubscription({ query_string: 'plan_code=sms_termii&status=active' }).then(({ size }) => {
      setHasSubscription(!!size);
    });

    Promise.all([fetchUserData(), fetchSMSs()]).then(([{ user }, { size }]) => {
      const { onboarding } = user;
      setOnboardingConfig((conf) => ({
        ...conf,
        hidden: !!(onboarding?.sms && onboarding?.sms.hidden),
        has_created_message: !!size
      }));
    });
  }, []);

  return (
    <>
      {!onboarding_config.hidden && (
        <>
          <LandingCard
            data={landing_data}
            control_config={onboarding_config}
            onHide={setOnboardingConfig}
          />
          <Spacer multiple={4} />
        </>
      )}
      {!has_subscription ? (
        <></>
      ) : (
        <Routes>
          <Route path="/*" element={<SMSListPage />} />
        </Routes>
      )}
    </>
  );
};
