import React, { useEffect, useState } from 'react';
import { useSMSService } from 'hooks/messaging/sms';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import styles from '../sms.module.css';
import GmModal from 'app/shared/modal/modal';
import { SMSmessageForm } from '../form';
import { IconInput } from 'app/shared/input';
import { Spacer } from 'app/layouts/generic';
import { SMSListItem } from './item';
import { useDispatch, useSelector } from 'react-redux';
import { addManySMSToStore, addSMSSearchResultsToStore } from 'store/actions/sms';

export const SMSListPage = () => {
  const dispatch = useDispatch();
  const messages_in_store = useSelector((store) => store.sms);

  const { fetchSMSs, searchSMS } = useSMSService();

  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [page_number, setPageNumber] = useState(0);
  const [show_creation_modal, setShowCreationModal] = useState(false);

  useEffect(() => {
    const sorted_messages = Object.values(messages_in_store).sort(
      (a, b) => b.time_stamp - a.time_stamp
    );

    setMessages(sorted_messages);
  }, [messages_in_store]);

  useEffect(() => {
    handleRequest(keyword, 0);
    setPageNumber(() => 0);
  }, [keyword]);

  const handleDataRequest = async (page = 0, population = 50) => {
    setLoading(true);
    const { messages } = await fetchSMSs({
      query_string: `page=${page}&population=${population}&sort_by=-created_on`
    });

    dispatch(addManySMSToStore(messages));
    setLoading(false);
  };

  const handleRequest = (keyword, page_number) => {
    if (!keyword) handleDataRequest(page_number);
    else handleSearch(keyword, page_number);
  };

  const handleSearch = async (keyword = '', page = 0, population = 50) => {
    setLoading(true);
    const { messages } = await searchSMS('title,description,sender_id,type', keyword, {
      query_string: `page=${page}&population=${population}&sort_by=-created_on`
    });

    setLoading(false);
    if (page === 0) dispatch(addSMSSearchResultsToStore(messages));
    else dispatch(addManySMSToStore(messages));
  };

  const loadMore = () => {
    const page_num = page_number + 1;
    handleRequest(keyword, page_num);
    setPageNumber(() => page_num);
  };

  return (
    <>
      <GridRow num_of_columns={8}>
        <GridColumn span={2}>
          <IconInput icon_name="search" is_rounded value={keyword} onInput={setKeyword} />
        </GridColumn>
        <GridColumn span={4} />
        <GridColumn span={2}>
          <Button
            icon_name="add"
            className={styles.roundedBtn}
            text="New Message"
            onClick={() => setShowCreationModal(true)}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      <GridRow>
        {messages.map((message) => (
          <GridColumn key={message.id} span={4}>
            <SMSListItem item_data={message} />
          </GridColumn>
        ))}
      </GridRow>
      <Spacer multiple={4} />
      <GridRow num_of_columns={5}>
        <GridColumn span={2} />
        <GridColumn>
          <Button text="Load More" loading={loading} onClick={loadMore} type="secondary" />
        </GridColumn>
        <GridColumn span={2} />
      </GridRow>
      <GmModal
        show_modal={show_creation_modal}
        show_title
        title="Create message"
        onClose={() => setShowCreationModal(false)}
      >
        <SMSmessageForm />
      </GmModal>
    </>
  );
};
